import { useState } from 'react'
import { HeartButton, ProductPrice, ProductQuantityInput, Button, ProductImage, SimpleImage } from '@ten24/slatwallreactlibrary/components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { addToCart } from '@ten24/slatwallreactlibrary/actions'
import { getBrandRoute, getProductRoute } from '@ten24/slatwallreactlibrary/selectors'
import { useProductPrice } from '@ten24/slatwallreactlibrary/hooks'
import { VerifiedButton } from '../../components/VerifiedButton/VerifiedButton'
import defaultMissingProduct from '../../assets/images/missing-product.png'
// render quantity input on the basis of config
// renders input on text, dropdown on dropdown
const ProductCard = props => {
  const { productName, thumbnail, product_productName, productCode, product_productCode, product_productCenterToCenterDecimal, calculatedQATS, sku_calculatedQATS, skuOrderMinimumQuantity, skuOrderMaximumQuantity, salePrice, urlTitle, productUrlTitle, brand: brandName, brandUrlTitle, imageFallbackFileName = '', listPrice, images, imageFile, productClearance, imagePath, skuID = '', sku_skuID = '', skuCode, sku_skuCode, skuName, sku_skuName, product_landrplus, landrplus, sku_imageFile, defaultSku_skuID } = props
  const mysku = {
    skuOrderMinimumQuantity: skuOrderMinimumQuantity || 1,
    skuOrderMaximumQuantity: skuOrderMaximumQuantity || 9999,
    calculatedQATS: calculatedQATS || sku_calculatedQATS,
  }
  const { t } = useTranslation()
  const brand = useSelector(getBrandRoute)
  const { showAddToCart } = useProductPrice({ salePrice: salePrice, listPrice: listPrice })
  const product = useSelector(getProductRoute)
  const productLink = `/${product}/${urlTitle || productUrlTitle}` + (skuID.length || sku_skuID.length ? `?skuid=${skuID || sku_skuID}` : '')
  const useResizedImage = images && images?.length > 0
  const CenterToCenterDecimal = parseFloat(product_productCenterToCenterDecimal).toFixed(0) + " ''CC"
  const useCenterToCenter = product_productCenterToCenterDecimal && product_productCenterToCenterDecimal > 0
  const [quantity, setQuantity] = useState(mysku.skuOrderMinimumQuantity)
  const dispatch = useDispatch()
  const onErrorCallback = e => {
    e.target.onerror = null
    e.target.src = defaultMissingProduct
  }
  return (
    <div className="card product-card pb-2 pt-3 mx-2">
      {productClearance === true && <span className="badge badge-primary">{t('frontend.core.special')}</span>}
      <HeartButton skuID={skuID || sku_skuID || defaultSku_skuID} />
      <VerifiedButton landrplus={product_landrplus || landrplus} />
      <Link className="card-img-top d-block overflow-hidden" to={productLink}>
        {useResizedImage && <SimpleImage className="d-block mx-auto" src={images[0]} alt={productName || product_productName} />}
        {!useResizedImage && imagePath && <ProductImage imageFile={imagePath} skuID={skuID} customPath="/" fallbackFileName={imageFallbackFileName} />}
        {/* {!useResizedImage && imageFile && <ProductImage imageFile={imageFile} skuID={skuID} fallbackFileName={imageFallbackFileName} />} */}
        {!useResizedImage && sku_imageFile && <ProductImage imageFile={sku_imageFile} skuID={skuID} fallbackFileName={imageFallbackFileName} />}
        {thumbnail && <img className="productImage lr-productImage" src={`${thumbnail}`} alt={productName || product_productName} onError={onErrorCallback} />}
        {imageFile && <img className="productImage lr-productImage" src={`${imageFile}`} alt={productName || product_productName} onError={onErrorCallback} />}
      </Link>
      <div className="card-body py-2 text-left">
        {brandUrlTitle && (
          <Link className="product-meta d-block font-size-xs pb-1" to={`/${brand}/${brandUrlTitle}`}>
            {brandName}
          </Link>
        )}
        {!skuCode && productCode && (
          <h3 className="font-size-sm text-muted mb-2">
            <Link to={productLink}>{productCode}</Link>
          </h3>
        )}
        {skuCode && (
          <h3 className="product-code mb-2">
            <Link to={productLink}>{skuCode}</Link>
          </h3>
        )}
        {!skuCode && !productCode && (product_productCode || sku_skuCode) && (
          <h3 className="product-code mb-2">
            <Link to={productLink}>{sku_skuCode || product_productCode}</Link>
          </h3>
        )}
        <h3 className="product-title">
          <Link to={productLink}>
            {skuName || sku_skuName || product_productName} {useCenterToCenter && CenterToCenterDecimal}
          </Link>
        </h3>
        <div className="d-flex justify-content-between">
          <ProductPrice salePrice={salePrice} listPrice={listPrice} />
        </div>
      </div>
      {showAddToCart && (
        <div className="addToCartForm">
          <ProductQuantityInput setQuantity={setQuantity} quantity={quantity} sku={mysku} />
          <Button
            className="btn btn-primary btn-block"
            onClick={event => {
              dispatch(addToCart(skuID || defaultSku_skuID, quantity, t('frontend.checkout.item_added_successfully')))
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          >
            <i className="far fa-shopping-cart font-size-lg mr-2"></i>
            {t('frontend.product.add_to_cart')}
          </Button>
        </div>
      )}
    </div>
  )
}
export { ProductCard }
