import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-use'
import { AccountBubble, CartMenuItem } from '@ten24/slatwallreactlibrary/components'
import { AccountImpersonationBanner } from '../Account'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { useUtilities } from '@ten24/slatwallreactlibrary/hooks'

const UtilityBar =  ({ socialItems = [] }) => {

  if (!socialItems) {
    return null
  }

  return (
   <nav className="navbar-default top-bar">
    <div className="container">
      <div className="row justify-content-end">
        <div className="col-auto">
          <ul className="nav ms-auto justify-content-end">
          {socialItems.map(menuItem => {
          return (
            <li key={menuItem.linkTitle} className="nav-item">
              <a href={menuItem.linkUrl} className="nav-link" target="_blank" rel="noreferrer">
                <i className={`bi bi-${menuItem.linkTitle}`}></i>
              </a>
            </li>
            )
          })}
          </ul>
        </div>
      </div>
    </div>
   </nav>
  )
}

const MegaMenuPanel = ({ subMenu = [] }) => {
  const { eventHandlerForWSIWYG } = useUtilities()
  return (
    <div className="d-flex flex-wrap px-2">
      {subMenu.map((menuPanel, index) => {
      let contentBody = menuPanel?.contentBody
      if (!contentBody.replace(/\s/g, '').length) {
        contentBody = menuPanel?.customBody
      }
        return (
          <div key={index} className="mega-dropdown-column py-4 px-2">
            <Link to={menuPanel.title_link || ''}>{menuPanel.title}</Link>
            <div
              className="widget widget-links mb-3"
              onClick={eventHandlerForWSIWYG}
              data-toggle="collapse" 
              data-target=".navbar-collapse.show"
              dangerouslySetInnerHTML={{
                __html: contentBody,
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

const MegaMenu = ({ menuItems = [] }) => {
  const { t } = useTranslation()
  return (
    <ul className="navbar-nav nav-categories w-100">
      {menuItems.map(menuItem => {
        return (
          <li key={menuItem.linkTitle} className="nav-item dropdown">
            {!menuItem.columns.length && (
              <Link className="nav-link" to={menuItem.linkUrl}>
                {menuItem.linkTitle}
              </Link>
            )}
            {menuItem.columns.length && (
              <>
                <a className="nav-link dropdown-toggle" href={menuItem.linkUrl} data-toggle="dropdown">
                  {menuItem.linkTitle}
                </a>
                <div className="mega-menu dropdown-menu border pt-0 pb-3">
                  <div className="nav-shop-all">
                    <Link to={menuItem.linkUrl}>
                      {`${t('frontend.nav.shopall')} ${menuItem.linkTitle}`}
                      <i className="bi bi-arrow-right ms-2"></i>
                    </Link>
                  </div>
                  <MegaMenuPanel subMenu={menuItem.columns} />
                </div>
              </>
            )}
          </li>
        )
      })}
    </ul>
  )
}

const NavBar = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const menu_items = useSelector(state => state.content?.header?.mega_menu?.menu_items)
  const { shopByManufacturer } = useSelector(state => state.configuration)
  const mobileTextInput = useRef(null)
  if (!menu_items) {
    return null
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-stuck-menu mt-2 pt-0 pb-0">
      <div className="container p-0">
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="input-group-overlay d-lg-none my-3 ms-0 form-box-align">
            <div className="input-group-prepend-overlay">
                  <span className="input-group-text">
                    <i
                      className="far fa-search"
                      onClick={e => {
                        e.preventDefault()
                        history.push({
                          pathname: '/search',
                          search: mobileTextInput.stringify({ keyword: mobileTextInput.current.value }, { arrayFormat: 'comma' }),
                        })
                        mobileTextInput.current.value = ''
                      }}
                    />
                  </span>
                </div>
                <input
                  className="form-control prepended-form-control"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      history.push({
                        pathname: '/search',
                        search: queryString.stringify({ keyword: e.target.value }, { arrayFormat: 'comma' }),
                      })
                      mobileTextInput.current.value = ''
                    }
                  }}
                  type="search"
                  ref={mobileTextInput}
                  placeholder={t('frontend.search.placeholder')}
                />
              </div>
            <div className="d-lg-none d-xl-none">
              <MainNavBar />
            </div>
          <MegaMenu menuItems={menu_items} />
          {shopByManufacturer.showInMenu && (
            <ul className="navbar-nav mega-nav ">
              <li className="nav-item">
                <Link className="nav-link" to={shopByManufacturer.slug}>
                  <i className="far fa-industry-alt mr-2"></i>
                  {t('frontend.nav.manufacturer')}
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  )
}

const MainNavBar = () => {
  const { eventHandlerForWSIWYG } = useUtilities()
  const mainNavigation = useSelector(state => state.content?.header?.utility_menu?.menu_items)
  const location = useLocation()
  return (
    <ul className="nav justify-content-lg-center justify-content-lg-end">
      {mainNavigation && !Array.isArray(mainNavigation) && (
        <div
          className="d-lg-flex d-none"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{
            __html: mainNavigation || '',
          }}
        />
      )}
      {mainNavigation &&
        Array.isArray(mainNavigation) &&
        mainNavigation?.map(({ bootstrapIconClass, linkUrl, linkTitle }) => {
          return (
            <Link to={linkUrl} className="nav-item link-button" key={linkUrl}>
              <span className={`nav-link text-center ${location?.pathname === linkUrl && `active`}`} aria-current="page">
                <i className={bootstrapIconClass}></i> <span className="d-block">{linkTitle}</span>
              </span>
            </Link>
          )
        })}
    </ul>

  )
}

function Header({ logo, mobileLogo }) {
  const { t } = useTranslation()
  let history = useHistory()
  const social_items = useSelector(state => state.content?.header?.social_menu?.social_items)
  const content = useSelector(state => state.content)
  let mainNavigation = content['header/main-navigation'] ? content['header/main-navigation'].customBody : ''
  if (!mainNavigation) {
    mainNavigation = content['header']?.utility_menu?.menu_items
  }
  const textInput = useRef(null)
  const toggleMenuButton = useRef(null)
  let { eventHandlerForWSIWYG } = useUtilities()

  useEffect(() => {
    const unload = history.listen(location => {
      toggleNav()
    })
    return () => {
      unload()
    }
  }, [history])

  /**
   * Toggle menu , while the sub-menu is clicked
   */
  const toggleNav = () => {
    if (toggleMenuButton.current && toggleMenuButton.current.ariaExpanded === 'true') {
      toggleMenuButton.current.click()
    }
  }

  /**
   * Search Input validation
   * @return {boolean}
   */
  const searchInputValidation = () => {
    textInput.current.value = textInput.current.value.toString().trim() // trim white spaces around input

    // check input validation
    if (!textInput.current.checkValidity()) {
      textInput.current.value = ''
      return false
    }

    // minimum 3 keywords required to make a search
    if (textInput.current.value.length < 3) {
      return false
    }

    return true
  }

  /**
   * Search Products
   * @param {event} e
   * @return {void}
   */
  const searchForProducts = e => {
    if ((e.key === 'Enter' || e?.type === 'click') && searchInputValidation()) {
      e.preventDefault()
      history.push({
        pathname: '/search',
        search: queryString.stringify({ keyword: textInput.current.value }, { arrayFormat: 'comma' }),
      })
      textInput.current.value = ''
    }
  }

  return (
    <>  
     <AccountImpersonationBanner />
     <UtilityBar socialItems={social_items}/>
     <header className="shadow-sm">
      <div className="navbar-sticky bg-light">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Link className="navbar-brand d-none d-md-block mr-3 flex-shrink-0" to="/">
              <img style={{ maxHeight: '75px' }} src={logo} alt={t('frontend.logo')} />
            </Link>
            <Link className="navbar-brand d-md-none mr-2" to="/">
              <img src={mobileLogo} style={{ minWidth: '90px' }} alt={t('frontend.logo')} />
            </Link>

            <div className="navbar-right">
              <div className="navbar-topright">
                <div className="input-group-overlay d-none d-lg-flex">
                  <input className="form-control appended-form-control" type="text" required ref={textInput} onKeyDown={searchForProducts} placeholder={t('frontend.search.placeholder')} />
                  <div className="input-group-append-overlay">
                    <span className="input-group-text">
                      <i style={{ cursor: 'pointer' }} onClick={searchForProducts} className="far fa-search"></i>
                    </span>
                  </div>
                </div>
                <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                  <button ref={toggleMenuButton} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" id="menuToggleButton">
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <Link className="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2" to="/my-account" data-toggle="modal">
                    <AccountBubble />
                  </Link>

                  <CartMenuItem />
                </div>
              </div>

              <div
                className="navbar-main-links"
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: mainNavigation,
                }}
              />
            </div>
          </div>
        </nav>
        <NavBar />
      </div>
     </header>
    </> 
  )
}

export { Header }
