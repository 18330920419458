import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { useUtilities } from '@ten24/slatwallreactlibrary/hooks'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'
import { SimpleImage } from '@ten24/slatwallreactlibrary/components'

const BannerSlide = ({ contentBody, title, imagePath, linkUrl, linkLabel, slideKey }) => {
  const { host } = useSelector(state => state.configuration.theme)
  let { eventHandlerForWSIWYG } = useUtilities()

  return (
    <div className="hero text-white text-center pb-4 hero-image" style={{ backgroundImage: `url(${host}/${imagePath}` }}>
      <div className="container">
        <div className="main-banner">
          <div index={slideKey} className="repeater">
            <h2 className="h2">{title}</h2>
            <p onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
            {linkUrl && linkUrl.trim().length > 0 && (
              <a href={linkUrl} className="btn btn-light btn-long">
                {linkLabel}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
/*
TODO: we shoud match contentful

only use how is image url is not FQDN

*/

const ContentSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Slider className="main-slider slider-dark" {...settings}>
        {props?.slider && props.slider?.slides?.length > 0 &&
        props.slider.slides
        .sort((a, b) => { return a.sortOrder - b.sortOrder })
        .map((slideData, index) => {
          return <BannerSlide {...slideData} key={index} slideKey={index} />
        })}
    </Slider>
  )
}


const ContentCMSSlider = ({ slider }) => {
  const { t } = useTranslation()
  let { eventHandlerForWSIWYG } = useUtilities()

  useEffect(() => {
    if (slider) {
      var carousel = new bootstrap.Carousel(document.getElementById('carousel'), {
        interval: 5000,
        ride: true,
        pause: false,
      })
      carousel.cycle()
    }
  }, [slider])
  if (!slider) return null
  return (
    <div className="hero content-slider">
      {slider?.slides && slider.slides.length > 0 && (
        <div id="carousel" className="carousel slide" data-ride="carousel">
          {slider?.slides?.length > 1 && (
            <div className="carousel-indicators">
              {slider.slides.map(({ title }, idx) => {
                return <button key={title} type="button" data-target="#carousel" data-slide-to={idx} className={idx === 0 ? 'active' : ''} aria-current="true" aria-label={`Slide ${idx}`}></button>
              })}
            </div>
          )}
          <div className="carousel-inner">
            {slider.slides.map(({ contentBody, title, imagePath, linkUrl, linkLabel }, key) => {
              return (
                <div key={title} className={key === 0 ? 'carousel-item active' : 'carousel-item'}>
                  <div className="carousel-image">
                    <SimpleImage src={imagePath} alt="carouselImage" />
                  </div>
                  <div className="carousel-caption">
                    <h2 className="display-3">{title}</h2>
                    <p onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
                    {linkLabel?.trim()?.length > 0 && (
                      <a onClick={eventHandlerForWSIWYG} href={linkUrl ? `/${linkUrl}` : '/#'} className="btn btn-primary text-white px-4 rounded-pill">
                        {linkLabel}
                      </a>
                    )}
                  </div>
                </div>
              )
            })}
          </div>

          {slider?.slides && slider.slides.length > 1 && (
            <>
              <button className="carousel-control-prev" type="button" data-target="#carousel" data-slide="prev">
                <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                <span className="visually-hidden">{t('frontend.core.previous')}</span>
              </button>
              <button className="carousel-control-next" type="button" data-target="#carousel" data-slide="next">
                <span className="visually-hidden">{t('frontend.core.next')}</span>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export { ContentSlider, ContentCMSSlider }
